<template>
  <div class="main page-container">
    <!--    <el-breadcrumb separator-class="el-icon-arrow-right">-->
    <!--      <el-breadcrumb-item :to="{ path: '/welcome' }">主页</el-breadcrumb-item>-->
    <!--      <el-breadcrumb-item :to="{path: '/taskcenter'}">任务中心</el-breadcrumb-item>-->
    <!--      <el-breadcrumb-item>随访详情</el-breadcrumb-item>-->
    <!--    </el-breadcrumb>-->
    <Breadcrumb></Breadcrumb>

    <patientinfocard :patient.sync="patientInfo"></patientinfocard>
    <div class="formregion_warpper">
      <div class="_formgion">
        <div class="nodataShow" v-if="isDateList == true">暂无随访详情</div>
        <div class="formgion_item">
          <div class="title">随访详情:</div>
          <!-- <el-form
            ref="form"
            :model="form"
            label-width="80px"
            label-position="top"
            v-if="isDateList == false"
          >
            <el-form-item
              :label="question.question_content"
              v-for="(question, _index) in questionList"
              :key="_index"
            >
              <el-radio-group v-model="question.answer_id" @change="dealAnswer">
                <el-radio
                  :label="answer.answer_id"
                  v-for="(answer, index) in question.answer"
                  :disabled="
                    answer.answer_id == question.answer_id ? false : true
                  "
                  :key="index"
                  >{{ answer.answer_content }}</el-radio
                >
              </el-radio-group>
              <div
                class="subsquestion_box"
                v-for="(subsquestion, _ind) in question.subsList"
                :key="_ind"
              >
                <div class="subsquestion_title">
                  {{ subsquestion.question_content }}
                </div>
                <el-radio-group v-model="subsquestion.answer_id">
                  <el-radio
                    :label="subsanswer.answer_id"
                    v-for="(subsanswer, ind) in subsquestion.answer"
                    :disabled="
                      subsanswer.answer_id == subsquestion.answer_id
                        ? false
                        : true
                    "
                    :key="ind"
                    >{{ subsanswer.answer_content }}</el-radio
                  >
                </el-radio-group>
              </div>
            </el-form-item>
          </el-form> -->
          <ul class="question">
            <li v-for="(item, i) in currFollowQuestion" :key="i">
              <div class="ques">{{ i + 1 + "、" + item.question_content }}</div>
              <div class="ass">
                <div class="ass-item">
                  <span
                    v-for="(ite, idx) in item.answer"
                    :class="
                      item.answerList.includes(ite.answerId) ? 'Active' : ''
                    "
                    :key="idx"
                    >{{ abc[idx] + "、" + ite.answerContent }}</span
                  >
                  <div v-if="item.questionType == 3">
                    {{ item.answerInfo[0].answerContent }}
                  </div>
                </div>
                <!--            <div class="ass-id"-->
                <!--                 v-if="followInfo.status !== 2 && followInfo.status !== 4 && followInfo.status !== 1">-->
                <!--              答案：<span>{{ search(item.answer, item.answerInfo) || "" }}</span>-->
                <!--            </div>-->
              </div>
            </li>
          </ul>
        </div>
        <div class="formgion_item">
          <div class="title">工单记录:</div>
          <el-table :data="tableData" border size="mini" style="width: 100%">
            <el-table-column prop="createTime" label="创建时间" width="180">
              <template slot-scope="socpe">{{
                socpe.row.createTime | dateFormat("YYYY-MM-DD HH:mm:ss")
              }}</template>
            </el-table-column>
            <el-table-column prop="problemDescription" label="问题描述">
            </el-table-column>
            <el-table-column prop="assistantConclusion" label="医助处理结果">
            </el-table-column>
          </el-table>
        </div>
        <div class="formgion_item">
          <div class="title">医助结论:</div>
          <div class="conclusion">{{ conclusion }}</div>
          <div v-if="conclusion == ''" class="nodata">暂无医助结论</div>
        </div>
      </div>
      <div class="formgion-left">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="呼叫" name="first">
            <div class="relationsPhoneList">
              <div
                class="title"
                v-for="(item, index) in relationsList"
                :key="index"
              >
                <div>{{ item.name }} :{{ item.phone }}</div>
                <span @click="telNum(item.phone)" style="cursor: pointer"
                  >呼叫</span
                >
              </div>
            </div>
            <el-form :model="teachFrom" ref="teachFromRef" label-width="80px">
              <el-form-item
                label="是否应答"
                prop="isAnswer"
                label-width="100px"
              >
                <el-radio v-model="teachFrom.isAnswer" :label="1">是</el-radio>
                <el-radio v-model="teachFrom.isAnswer" :label="0" disabled
                  >否</el-radio
                >
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="添加工单" name="second">
            <el-form :model="teachFrom" ref="teachFromRef" label-width="80px">
              <el-form-item
                label="医生工作室"
                prop="office"
                label-width="100px"
              >
                <el-input v-model="patientInfo.groupName" disabled></el-input>
              </el-form-item>
              <el-form-item
                label="患者姓名"
                prop="userName"
                label-width="100px"
              >
                <el-input v-model="patientInfo.userName" disabled></el-input>
              </el-form-item>
              <el-form-item label="事件描述" prop="des" label-width="100px">
                <el-input
                  v-model="teachFrom.des"
                  type="textarea"
                  :maxlength="100"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="btngroups">
              <el-button @click="cancelbtn">取 消</el-button>
              <el-button type="primary" @click="confirmEvent">确 定</el-button>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import patientinfocard from "@/views/taskcenter/components/patientinfocard";
import { Debounce } from "../../tools/utils";
export default {
  name: "followdetails",
  components: { patientinfocard },
  data() {
    return {
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      patientInfo: {},
      questionList: [],
      //提交表单参数
      map: [],
      isDateList: false,
      imageList: [],
      conclusion: "",
      activeName: "first",
      relationsList: [],
      teachFrom: {
        teachId: "",
        userId: "",
        isAnswer: 1,
        des: "",
        teachTime: "",
        name: "",
        office: "",
        nextTime: "",
        handM: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now(); //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
      userInfo: {},
      tableData: [],
      followUpId: "",
      userId: "",
      disabled: false,
      myDeBonce: "",
      abc: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      currFollowQuestion: [],
    };
  },
  created() {
    this.myDeBonce = new Debounce(500);

    this.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    this.followUpId = this.$route.query.followUpId;
    this.userId = this.$route.query.userId;
    this.getFollowQuestionList(this.followUpId);
    this.getPatienInfo(this.userId);
    this.getRelationsList(this.userId);
    this.getOrderTableList(this.userId, this.followUpId);
  },
  methods: {
    onSubmit() {
      console.log("问卷调查");
    },
    // 排序
    compare(property) {
      return function (a, b) {
        return a[property] - b[property];
      };
    },
    //获取随访问题详情列表
    async getFollowQuestionList(followUpId) {
      const params = {
        followUpId: followUpId,
      };
      const res = await this.$http.getFollowUpdetails(params);
      console.log(res, "================打印结果");
      this.currFollowQuestion = res.result.data.followQuestionDetail.map(
        (v) => {
          let arr = v.chooseContent ? JSON.parse(v.chooseContent) : [];
          let answerInfo = v.answerInfo ? JSON.parse(v.answerInfo) : [];
          let answerList = answerInfo.map((it) => it.answerId);
          arr.sort(this.compare("answerId"));

          if (v.questionContent == "近期有无再次住院情况?") {
            arr.forEach((item) => {
              if (item.answerContent == "有") {
                let answerInfo = JSON.parse(v.answerInfo);
                let str = "";
                answerInfo.forEach((it) => {
                  if (it.hospitalTime) {
                    str += "再住院时间：" + it.hospitalTime + "；";
                  }
                  if (it.reason) {
                    str += "原因：" + it.reason + "；";
                  }
                  if (it.inpatientDepartment) {
                    str += "住院科室：" + it.inpatientDepartment + "；";
                  }
                });
                if (str)
                  item.answerContent = item.answerContent + "(" + str + ")";
              }
            });
          }

          if (v.questionContent == "请选择不适类型") {
            arr.forEach((item) => {
              if (item.answerContent == "其他") {
                let answerInfo = JSON.parse(v.answerInfo);
                let str = "";
                answerInfo.forEach((it) => {
                  if (it.specificSituation) {
                    str += it.specificSituation;
                  }
                });

                if (str)
                  item.answerContent = item.answerContent + "(" + str + ")";
              }
            });
          }

          return {
            answer_id: v.answer_id,
            follow_up_question_id: v.followUpQuestionId,
            question_content: v.questionContent,
            question_id: v.questionId,
            type: v.type,
            answer: arr,
            answerInfo: answerInfo,
            questionType: v.questionType,
            answerList,
          };
        }
      );

      res.result.data.questionsAndAnswers.forEach((item) => {
        item.answer = JSON.parse(item.answer);
        item.answer_id = item.answer_id + "";
        if (item.sid != 1) {
          let info = res.result.data.ships.find(
            (re) => re.s_question_id == item.question_id
          );
          console.log(info, "////");
          item["pid"] = info ? info.p_question_id : "";
        }
      });

      let parentQues = res.result.data.questionsAndAnswers.filter(
        (re) => !re.pid
      );
      let subsQues = res.result.data.questionsAndAnswers.filter((re) => re.pid);
      parentQues.forEach((item) => {
        item.subsList = [];
        let info = subsQues.filter((re) => re.pid == item.question_id);
        if (info) {
          item.subsList = info;
        }
      });
      console.log(parentQues, "////----");
      console.log(subsQues, "////");
      console.log(res.result.data.questionsAndAnswers);
      if (res.result.data.questionsAndAnswers.length == 0) {
        this.isDateList = true;
      } else {
        this.isDateList = false;
      }

      this.questionList = parentQues;
      console.log(this.questionList);
      this.imageList = res.result.data.url;
      if (res.result.data.conclusion) {
        this.conclusion = res.result.data.conclusion.conclusion;
      } else {
        this.conclusion = "";
      }
    },
    dealAnswer(value) {
      console.log(value);
    },
    //获取患者信息
    async getPatienInfo(id) {
      const params = {
        userId: id,
      };
      const res = await this.$http.getPatientInfoMation(params);
      console.log(res);
      res.result.data.age = this.$moment().diff(res.result.data.birth, "years");
      console.log(res.result.data.age);
      if (res.result.data.gender == 1) {
        res.result.data.gender = "男";
      } else if (res.result.data.gender == 2) {
        res.result.data.gender = "女";
      } else {
        res.result.data.gender = "";
      }
      this.patientInfo = res.result.data;
    },

    //
    handleClick() {
      console.log(this.activeName);
    },
    //获取当前患者通讯录
    getRelationsList(val) {
      let params = {
        userId: val,
      };
      this.$http.getRelationsPhone(params).then((res) => {
        console.log(res);
        this.relationsList = res.result.data;
      });
    },
    cancelbtn() {
      this.teachFrom.handM = "";
      this.teachFrom.nextTime = "";
      this.teachFrom.des = "";
    },
    confirmEvent() {
      this.addOrderConfirm();
      if (this.teachFrom.isAnswer === 1) {
        this.addOrderConfirm();
      } else {
        this.updateFollowUpReq();
      }
    },
    //下次提醒
    async updateFollowUpReq() {
      await this.myDeBonce.debounceEnd();

      if (this.teachFrom.nextTime === "" || this.teachFrom.handM === "")
        return this.$message.error("请先填写下次提醒时间");
      let params = {
        followUpId: this.followUpId,
        isAnswer: 0,
        nextTime: this.teachFrom.nextTime + " " + this.teachFrom.handM,
      };
      console.log(params);
      console.log(this.teachFrom.nextTime);
      console.log(this.teachFrom.handM);
      const res = await this.$http.updateRemindFollowUp(params);
      if (res.result.msg === "success") {
        this.$message.success("处理成功!");
      } else {
        this.$message.error("处理失败!");
      }
    },
    //确认添加工单
    async addOrderConfirm() {
      await this.myDeBonce.debounceEnd();

      //隐藏对话框
      if (this.teachFrom.des === "")
        return this.$message.error("请先填写事件描述");
      let params = {
        followUpId: this.followUpId,
        customerServerId: this.userInfo.customerServerId,
        groupId: this.patientInfo.groupId,
        userId: this.userId,
        problemDescription: this.teachFrom.des,
        assistantId: this.patientInfo.assistantId,
        workOrderType: 0,
      };
      //添加工单请求
      const res = await this.$http.addWorkOrder(params);
      console.log(params);
      console.log(res, "请求结果=========================");
      if (res.result.msg == "success") {
        this.$message.success("添加成功!");
        this.teachFrom.des = "";
        this.getOrderTableList(this.userId);
      } else {
        this.$message.error("添加失败!");
      }
    },
    //获取工单信息
    async getOrderTableList(userId, followUpId) {
      let params = {
        customerServerId: this.userInfo.customerServerId,
        workOrderType: 0,
        userId,
        followUpId,
      };
      //获取工单信息
      const res = await this.$http.getWorkManageInfo(params);
      this.tableData = res.result.data;
      console.log(params);
      console.log(res, "请求结果=========================");
    },
    telNum(phone) {
      ClinkAgent.previewOutcall({ tel: phone });
    },
  },
};
</script>

<style scoped lang="less">
.main {
  position: relative;
}

.question {
  margin-bottom: 14px;

  li {
    text-align: left;
    color: #313131;
    font-size: 14px;
    line-height: 37px;

    .ass {
      display: flex;
      justify-content: space-between;

      .ass-item {
        flex: 1;
        // span{
        //     display: inline-block;
        //     width: 340px;
        // }
        span {
          display: inline-block;
          min-width: 240px;
          margin-right: 60px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .Active {
          color: #61a8ff;
        }
      }

      .ass-id {
        margin-right: 40px;

        span {
          color: #1abb0e;
        }
      }

      .del-icon {
        font-size: 20px;
        color: #bbb;
        margin-right: 46px;
        padding: 0;
      }
    }
  }
}
.formgion {
  width: 70%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
}
._formgion {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
}
._formgion::-webkit-scrollbar {
  display: none;
}
.formgion-left {
  width: 35%;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  margin-left: 16px;
  max-height: 650px;
  overflow-y: auto;
  overflow-x: hidden;
}
.formgion-left::-webkit-scrollbar {
  display: none;
}
.formgion::-webkit-scrollbar {
  display: none;
}
.formregion_warpper {
  overflow: hidden;
  margin-top: 10px;
  max-height: 680px;
  display: flex;
  justify-content: space-between;
}
.nodataShow {
  width: 500px;
  height: 100px;
  margin: 0 auto;
  font-size: 30px;
  font-weight: bold;
  color: dimgrey;
  text-align: center;
}
.el-form {
  margin: 0 20px 0;
}
.title {
  color: #0099cc;
  margin-bottom: 20px;
  font-size: 24px;
}
.image {
  width: 200px;
  height: 200px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.image_group {
  display: flex;
  flex-wrap: wrap;
}
.subsquestion_box {
  margin: 20px 20px 0;
}
.formgion_item {
  margin-bottom: 20px;
}
.nodata {
  color: #606266;
  font-size: 24px;
}
.relationsPhoneList {
  margin-bottom: 12px;
  .title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
    text-align: right;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    span {
      color: #409eff;
    }
    div:first-child {
      padding-left: 46px;
    }
  }
}
.teachTime-box {
  display: flex;
  flex-direction: column;
}
/deep/.el-form {
  margin: 0;
}
.btngroups {
  text-align: center;
}
</style>
